export const deviceConstants = {
	

	SET_DEVICES_REQUEST: "SET_DEVICES_REQUEST",
	SET_DEVICES_SUCCESS: "SET_DEVICES_SUCCESS",
	SET_DEVICES_FAILURE: "SET_DEVICES_FAILURE",

	
	HIDE_EDIT_DEVICE_MODAL: "HIDE_EDIT_DEVICE_MODAL",
	SHOW_EDIT_DEVICE_MODAL: "SHOW_EDIT_DEVICE_MODAL",

	DOCUMENT_HIDE_MODAL: "DOCUMENT_HIDE_MODAL",

	DEVICE_SET_REQUEST: "DEVICE_SET_REQUEST",
	DEVICE_SET_SUCCESS: "DEVICE_SET_SUCCESS",
	DEVICE_SET_FAILURE: "DEVICE_SET_FAILURE",
};
