export const userConstants = {
	
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",

	CONTACT_REQUEST: "CONTACT_REQUEST",
	CONTACT_SUCCESS: "CONTACT_SUCCESS",
	CONTACT_FAILURE: "CONTACT_FAILURE",

	
	REGISTER_REQUEST: "REGISTER_REQUEST",
	REGISTER_SUCCESS: "REGISTER_SUCCESS",
	REGISTER_FAILURE: "REGISTER_FAILURE",

	
	SEND_REGISTRATION_MAIL_REQUEST: "SEND_REGISTRATION_MAIL_REQUEST",
	SEND_REGISTRATION_MAIL_SUCCESS: "SEND_REGISTRATION_MAIL_SUCCESS",
	SEND_REGISTRATION_MAIL_FAILURE: "SEND_REGISTRATION_MAIL_FAILURE",

	
	PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
	PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
	PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",

	
	SEND_RESET_PASSWORD_MAIL_REQUEST: "SEND_RESET_PASSWORD_MAIL_REQUEST",
	SEND_RESET_PASSWORD_MAIL_SUCCESS: "SEND_RESET_PASSWORD_MAIL_SUCCESS",
	SEND_RESET_PASSWORD_MAIL_FAILURE: "SEND_RESET_PASSWORD_MAIL_FAILURE",
	
	ROLES_REQUEST: "ROLES_REQUEST",
	ROLES_SUCCESS: "ROLES_SUCCESS",
	ROLES_FAILURE: "ROLES_FAILURE",

	
	HIDE_MODAL: "HIDE_MODAL",
};
