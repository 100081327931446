export const libraryConstants = {
	
	

	
	LIBRARY_SUBMIT_REQUEST: "LIBRARY_SUBMIT_REQUEST",
	LIBRARY_SUBMIT_FAILURE: "LIBRARY_SUBMIT_FAILURE",
	LIBRARY_SUBMIT_SUCCESS: "LIBRARY_SUBMIT_SUCCESS",
	
	
	LIBRARY_GET_REQUEST: "LIBRARY_GET_REQUEST",
	LIBRARY_GET_FAILURE: "LIBRARY_GET_FAILURE",
	LIBRARY_GET_SUCCESS: "LIBRARY_GET_SUCCESS",
	
	DISTRIBUTORS_GET_REQUEST: "DISTRIBUTORS_GET_REQUEST",
	DISTRIBUTORS_GET_FAILURE: "DISTRIBUTORS_GET_FAILURE",
	DISTRIBUTORS_GET_SUCCESS: "DISTRIBUTORS_GET_SUCCESS",
	
	CATEGORIES_LIBRARY_GET_REQUEST: "CATEGORIES_LIBRARY_GET_REQUEST",
	CATEGORIES_LIBRARY_GET_FAILURE: "CATEGORIES_LIBRARY_GET_FAILURE",
	CATEGORIES_LIBRARY_GET_SUCCESS: "CATEGORIES_LIBRARY_GET_SUCCESS",

	SET_FILES_LIBRARY_REQUEST: "SET_FILES_LIBRARY_REQUEST",
	SET_FILES_LIBRARY_SUCCESS: "SET_FILES_LIBRARY_SUCCESS",
	SET_FILES_LIBRARY_FAILURE: "SET_FILES_LIBRARY_FAILURE",

	LIBRARY_REMOVE_REQUEST: "LIBRARY_REMOVE_REQUEST",
	LIBRARY_REMOVE_SUCCESS: "LIBRARY_REMOVE_SUCCESS",
	LIBRARY_REMOVE_FAILURE: "LIBRARY_REMOVE_FAILURE",

	LIBRARY_REQUEST_REMOVE_REQUEST: "LIBRARY_REQUEST_REMOVE_REQUEST",
	LIBRARY_REQUEST_REMOVE_SUCCESS: "LIBRARY_REQUEST_REMOVE_SUCCESS",
	LIBRARY_REQUEST_REMOVE_FAILURE: "LIBRARY_REQUEST_REMOVE_FAILURE",
	
	HIDE_REQUEST_MODAL: "HIDE_REQUEST_MODAL",

	LIBRARY_SET_REQUEST: "LIBRARY_SET_REQUEST",
	LIBRARY_SET_SUCCESS: "LIBRARY_SET_SUCCESS",
	LIBRARY_SET_FAILURE: "LIBRARY_SET_FAILURE",

	HIDE_ADD_LIBRARY_MODAL: "HIDE_ADD_LIBRARY_MODAL",
	SHOW_ADD_LIBRARY_MODAL: "SHOW_ADD_LIBRARY_MODAL",

	
	HIDE_EDIT_LIBRARY_MODAL: "HIDE_EDIT_LIBRARY_MODAL",
	SHOW_EDIT_LIBRARY_MODAL: "SHOW_EDIT_LIBRARY_MODAL",

	
	LIBRARY_HIDE_SUCCESS: "LIBRARY_HIDE_SUCCESS",
	LIBRARY_HIDE_FAIL: "LIBRARY_HIDE_FAIL",


	HIDE_MODAL_INFO: "HIDE_MODAL_INFO",
};
