export const documentsConstants = {
	
	DOCUMENTS_SUBMIT_REQUEST: "DOCUMENTS_SUBMIT_REQUEST",
	DOCUMENTS_SUBMIT_FAILURE: "DOCUMENTS_SUBMIT_FAILURE",
	DOCUMENTS_SUBMIT_SUCCESS: "DOCUMENTS_SUBMIT_SUCCESS",

	
	DOCUMENTS_SUBMIT_REQUEST: "DOCUMENTS_SUBMIT_REQUEST",
	DOCUMENTS_SUBMIT_FAILURE: "DOCUMENTS_SUBMIT_FAILURE",
	DOCUMENTS_SUBMIT_SUCCESS: "DOCUMENTS_SUBMIT_SUCCESS",
	
	DOCUMENTS_REQUEST_REMOVE_REQUEST: "DOCUMENTS_REQUEST_REMOVE_REQUEST",
	DOCUMENTS_REQUEST_REMOVE_SUCCESS: "DOCUMENTS_REQUEST_REMOVE_SUCCESS",
	DOCUMENTS_REQUEST_REMOVE_FAILURE: "DOCUMENTS_REQUEST_REMOVE_FAILURE",
	
	HIDE_REQUEST_MODAL: "HIDE_REQUEST_MODAL",

	DOCUMENTS_GET_REQUEST: "DOCUMENTS_GET_REQUEST",
	DOCUMENTS_GET_FAILURE: "DOCUMENTS_GET_FAILURE",
	DOCUMENTS_GET_SUCCESS: "DOCUMENTS_GET_SUCCESS",

	LIBRARY_GET_REQUEST: "LIBRARY_GET_REQUEST",
	LIBRARY_GET_FAILURE: "LIBRARY_GET_FAILURE",
	LIBRARY_GET_SUCCESS: "LIBRARY_GET_SUCCESS",
	
	CATEGORIES_GET_REQUEST: "CATEGORIES_GET_REQUEST",
	CATEGORIES_GET_FAILURE: "CATEGORIES_GET_FAILURE",
	CATEGORIES_GET_SUCCESS: "CATEGORIES_GET_SUCCESS",

	DISTRIBUTORS_GET_REQUEST: "DISTRIBUTORS_GET_REQUEST",
	DISTRIBUTORS_GET_FAILURE: "DISTRIBUTORS_GET_FAILURE",
	DISTRIBUTORS_GET_SUCCESS: "DISTRIBUTORS_GET_SUCCESS",
	
	CATEGORIES_LIBRARY_GET_REQUEST: "CATEGORIES_LIBRARY_GET_REQUEST",
	CATEGORIES_LIBRARY_GET_FAILURE: "CATEGORIES_LIBRARY_GET_FAILURE",
	CATEGORIES_LIBRARY_GET_SUCCESS: "CATEGORIES_LIBRARY_GET_SUCCESS",

	SET_FILES_REQUEST: "SET_FILES_REQUEST",
	SET_FILES_SUCCESS: "SET_FILES_SUCCESS",
	SET_FILES_FAILURE: "SET_FILES_FAILURE",

	DOCUMENT_REMOVE_REQUEST: "DOCUMENT_REMOVE_REQUEST",
	DOCUMENT_REMOVE_SUCCESS: "DOCUMENT_REMOVE_SUCCESS",
	DOCUMENT_REMOVE_FAILURE: "DOCUMENT_REMOVE_FAILURE",
	
	DOCUMENT_SET_REQUEST: "DOCUMENT_SET_REQUEST",
	DOCUMENT_SET_SUCCESS: "DOCUMENT_SET_SUCCESS",
	DOCUMENT_SET_FAILURE: "DOCUMENT_SET_FAILURE",

	HIDE_ADD_DOCUMENT_MODAL: "HIDE_ADD_DOCUMENT_MODAL",
	SHOW_ADD_DOCUMENT_MODAL: "SHOW_ADD_DOCUMENT_MODAL",

	
	HIDE_EDIT_DOCUMENT_MODAL: "HIDE_EDIT_DOCUMENT_MODAL",
	SHOW_EDIT_DOCUMENT_MODAL: "SHOW_EDIT_DOCUMENT_MODAL",

	
	DOCUMENT_HIDE_SUCCESS: "DOCUMENT_HIDE_SUCCESS",
	DOCUMENT_HIDE_FAIL: "DOCUMENT_HIDE_FAIL",


	HIDE_MODAL_INFO: "HIDE_MODAL_INFO",
};
